import * as Sentry from '@sentry/nuxt'

Sentry.init({
  dsn: useRuntimeConfig().public.sentry.dsn,
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'http://localhost:3001',
    'https://members.development.asifa-hollywood.org/',
    'https://members.staging.asifa-hollywood.org/',
    'https://members.asifa-hollywood.org/',
  ],
  integrations: [
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
    Sentry.vueIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: false,
    }),
  ],
})
